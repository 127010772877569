import { render, staticRenderFns } from "./platform-goods-detail.vue?vue&type=template&id=fbbc0d00&scoped=true&"
import script from "./platform-goods-detail.vue?vue&type=script&lang=ts&"
export * from "./platform-goods-detail.vue?vue&type=script&lang=ts&"
import style0 from "./platform-goods-detail.vue?vue&type=style&index=0&id=fbbc0d00&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbbc0d00",
  null
  
)

export default component.exports