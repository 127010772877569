






































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import {
    getLongRangeGoodsDetail,
    getLongRangeGoodsMcsSourceEnum,
} from "@/api/goods";

@Component({
    components: { LsDialog },
})
export default class DetailsPage extends Vue {
    activeName = ["1", "2"];
    viewDetailsInfo: any = {
        goods_data: {
            pc_body: "",
            mobile_body: "",
        },
    };
    loading: boolean = false;
    mcsSourceEnum: any = [];

    //获取在售平台字段映射
    async getGoodsMcsSourceEnum() {
        const res = await getLongRangeGoodsMcsSourceEnum({});
        if (res) this.mcsSourceEnum = res;
    }
    //获取详情
    async longRangeGoodsDetail(id: any) {
        this.loading = true;
        const res = await getLongRangeGoodsDetail({ id: id });
        this.loading = false;
        if (res) this.viewDetailsInfo = res;
    }
    back() {
        this.$router.push({
            path: "/goods/goods_center/goods",
            query: {
                t: '1',
                page: this.$route.query.page || '1',
                size: this.$route.query.size || '10',
            },
        });
    }
    async created() {
        const id = this.$route.query.id || null;
        await this.getGoodsMcsSourceEnum();
        this.longRangeGoodsDetail(id);
    }
}
